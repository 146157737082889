export const fr = {
    pick_a_station: 'Choisissez une station',
    view_stations: 'Voir les stations',
    search_a_station: 'Cherchez une station',
    recent_tracks: 'Derniére musique',
    nextTrack: 'Suivant',
    choose_animation: 'Choisir l\'animation',
    disable: 'Désactiver',
    cloud: 'CLOUD',
    waves: 'WAVES',
    halo: 'HALO',
    trunk: 'TRUNK',
    tw_listening_to : 'Entrain d\'écouter ',
    live_on : ' sur ',

}