export const pt = {
    pick_a_station: 'Escolha uma estação',
    view_stations: 'Ver Rádios',
    search_a_station: 'Pesquisar Rádio',
    recent_tracks: 'Anteriores',
    nextTrack: 'Próxima',
    choose_animation: 'Escolha Animação',
    disable: 'DESABILTAR',
    cloud: 'NUVENS',
    waves: 'ONDAS',
    halo: 'HALO',
    trunk: 'TRUNK',

}